import { Box } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 32px 24px;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 426px;
    margin: 0 auto;
    box-sizing: border-box;
  }

  .title-step {
    color: #18233d;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 16px;
  }

  .description-step {
    margin-bottom: 32px;
    font-weight: 500;
  }

  .incode-container {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .incode-container-mobile {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    z-index: 9999999;
  }

  .incode-container > div,
  .incode-container > div > div {
    width: auto;
    height: auto;
    position: relative;
  }
`;

export const StepsSection = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  gap: 15px;
  width: 100%;
  max-width: 280px;
  margin: 40px auto;
  overflow: hidden;
`;

export const Step = styled.div`
  width: 38px;
  height: 12px;
  border-radius: 5px;
  background-color: ${({ active }) => (active ? '#005640' : '#D8D8D8')};
  border: 1px solid ${({ active }) => (active ? '#005640' : '#D8D8D8')};
  font-weight: 500;
`;

export const CustomBox = styled(Box)`
  .error {
    border-bottom: 1px solid red;
    color: red;
  }

  .button {
    background-color: #00ae42;
    padding: 15px;
    border-radius: 24px;
    color: #fff;
    font-weight: bold;
  }

  .button:hover {
    background: #0dd260;
  }

  .send-again-button {
    color: #02bc70;
    font-weight: bold;
    background: none;
    font-size: 20px;
    border: none;
    cursor: pointer;
  }

  .disabled-button {
    background: none;
    font-size: 20px;
    border: none;
    color: gray;
    opacity: 0.5;
    cursor: default;
  }

  .mb-15 {
    margin-top: 15px;
  }

  .mb-20 {
    margin-top: 20px;
  }

  .mb-25 {
    margin-top: 25px;
  }
`;

export const Title = styled.h1`
  font-size: 28px;
`;

export const Description = styled.p`
  margin: 0;
  font-size: 18px;
`;
