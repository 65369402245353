import styled from 'styled-components';
import { media, mediaQueryMedium } from '../../helper';

export const GridContainer = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  ${mediaQueryMedium} {
    grid-template-columns: 1fr;
  }
`;

export const GridBox = styled.div`
  background-color: ${props => props.background};
  width: 100%;
  height: calc(100vh - 200px);

  > div > h1 {
    font-size: 38px;
  }

  .centered-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    height: 100%;
  }
`;

export const Content = styled.section`
  & h2 {
    margin-top: 100px;
    margin-bottom: 40px;
    text-align: center;
    font-size: 60px;
  }

  & h3 {
    font-size: 36px;
    margin-bottom: 40px;
    text-align: center;
  }

  & .form-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    max-width: 1140px;
    margin: 96px auto 0;
  }

  & .form-section img {
    max-width: 100%;
    width: 640px;
  }

  & .form-section .form {
    display: flex;
    flex-direction: column;
    width: 400px;
    max-width: 100%;
  }

  & .form-section .form > div {
    margin-bottom: 32px;
  }

  ${media.large} {
    & h2 {
      font-size: 56px;
      margin-bottom: 32px;
    }
  }

  @media (max-width: 1100px) {
    & .form-section {
      flex-direction: column;
      margin: 0 auto;
      max-width: 400px;
    }

    & .form-section .form {
      width: 100%;
    }

    & .form-section img {
      margin-bottom: 40px;
    }
  }

  ${media.medium} {
    & h2 {
      font-size: 40px;
      margin-bottom: 24px;
      margin-top: 0;
    }

    & h3 {
      font-size: 18px;
    }
  }
`;

export const Iframe = styled.iframe`
  width: 100%;
  height: 710px;
  border: 2px solid #fafafa;
`;
