/**
 * MainBannerPage
 *
 * This is the page we show when the user visits a url that doesn't have a route
 *
 */

import React from 'react';

import { Content } from './styledComponents';

export default function MainBanner() {
  return (
    <Content>
      <div className="vertical">
        <img
          src="./images/bubbles/topleft.svg"
          alt="TopLeftBubble"
          className="bubble top no-reverse"
        />
        <img
          src="./images/bubbles/bottomleft.svg"
          alt="BottomLeftBubble"
          className="bubble reverse-small"
        />
      </div>
      <div className="main-content">
        <h1>Tu vale en un click</h1>
        <div className="description">
          Con la modalidad de vales electónicos nunca fue tan fácil prestar y
          recibir dinero.
        </div>
        {/* <BotonQuieroSerDistribuidora /> */}
      </div>
      <div className="vertical inverse">
        <img
          src="./images/bubbles/topright.svg"
          alt="TopRightBubble"
          className="bubble top"
        />
        <img
          src="./images/bubbles/bottomright.svg"
          alt="BottomRightBubble"
          className="bubble"
        />
      </div>
    </Content>
  );
}
