import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const GlobalContext = createContext();

export const TYPES = {
  ERROR: 'error',
  SUCCESS: 'success',
};

export const GlobalActionsProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [type, setType] = useState('');

  const handleOpenSnackbar = (snackbarMessage, snackbarType) => {
    setOpen(true);
    setMessage(snackbarMessage);
    setType(snackbarType);
  };

  const handleCloseSnackbar = () => {
    setOpen(false);
  };
  return (
    <GlobalContext.Provider
      value={{ open, message, type, handleOpenSnackbar, handleCloseSnackbar }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

GlobalActionsProvider.propTypes = {
  children: PropTypes.element,
};

export const useGlobalActions = () => useContext(GlobalContext);
