import { create } from '@incodetech/welcome';
import { useCallback, useEffect, useState } from 'react';
import { authFetcher } from '../api/authFetcher';
import config from '../../../config';

const incodeConfig = {
  apiURL: config.incode.url,
  apiKey: config.incode.apiKey,
  lang: 'es',
};

const FLOW_ID = config.incode.flowId;

export const useIncode = ({ containerRef, onSubmit }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [incode, setIncode] = useState();
  const [session, setSession] = useState();
  const [savedInterviewId, setSavedInterviewId] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [incodeUrl, setIncodeUrl] = useState('');

  async function startIncode() {
    const incodeLoad = create(incodeConfig);
    await incodeLoad.warmup();
    return incodeLoad;
  }

  async function createIncodeSession(incodeInstance, interviewIdLoaded) {
    return incodeInstance.createSession('MX', null, {
      configurationId: FLOW_ID,
      ...(interviewIdLoaded && { interviewId: interviewIdLoaded }),
    });
  }

  async function loadIncodeId() {
    const { data } = await authFetcher.get('/personal-loan/incode-id');
    load(data.data);
  }

  async function saveIncodeId(sessionLoad, interviewIdLoaded) {
    if (
      sessionLoad &&
      sessionLoad.interviewId &&
      !interviewIdLoaded &&
      !savedInterviewId
    ) {
      const incodeIdSavePayload = {
        incodeId: sessionLoad.interviewId,
      };
      await authFetcher.post('/personal-loan/incode-id', incodeIdSavePayload);
      setSavedInterviewId(true);
    } else {
      console.log('No Session');
    }
  }

  async function load(interviewIdLoaded) {
    setIsLoading(true);
    const incodeLoad = await startIncode();
    setIncode(incodeLoad);
    const sessionLoad = await createIncodeSession(
      incodeLoad,
      interviewIdLoaded,
    );
    setSession(sessionLoad);
    saveIncodeId(sessionLoad, interviewIdLoaded);
    setIsLoading(false);
  }

  async function showFinishScreen() {
    console.log('Flow finished successfully!');
    setShowSuccess(true);
    await authFetcher.post('/personal-loan/incode');
    if (onSubmit) {
      onSubmit({});
    }
  }

  useEffect(() => {
    loadIncodeId();
  }, []);

  const fetchIncodeStatus = useCallback(async () => {
    if (!incode || !session) {
      return null;
    }

    const status = await incode.getOnboardingStatus(session);

    return status?.onboardingStatus;
  }, [incode, session]);

  useEffect(() => {
    if (incode && session) {
      if (incode.isDesktop()) {
        // Desktop behavior remains the same
        incode.renderQr(containerRef.current, {
          onSuccess: () => {
            showFinishScreen();
          },
          session,
          flowId: FLOW_ID,
        });
      } else {
        // Mobile behavior
        setIsMobile(true); // Mark that the current device is mobile
        // showFrontIne();
        const urlIncode = `${config.incode.onboardingUrl}/${config.incode.tenant}/flow/${config.incode.flowId}?interviewId=${session?.interviewId}&redirectionUrl=${config.self.url}/conviertete-en-distribuidora`;
        setIncodeUrl(urlIncode);
        window.open(urlIncode, '_blank');

        const intervalId = setInterval(async () => {
          const status = await fetchIncodeStatus();
          if (status === 'ONBOARDING_FINISHED') {
            clearInterval(intervalId);
            showFinishScreen();
          }
        }, 1000);
      }
    }
  }, [incode, session]);

  return {
    isLoading,
    showSuccess,
    isMobile,
    incodeUrl,
  };
};
