import Axios from 'axios';
import config from '../../../config';

export const authFetcher = Axios.create({
  baseURL: config.auth.url,
  headers: {
    'x-api-key': config.auth.apiKey,
  },
  withCredentials: true,
});
