import React from 'react';
import { Snackbar as MUISnackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useGlobalActions } from '../../context/globalActions';

export const Snackbar = () => {
  const { open, type, message, handleCloseSnackbar } = useGlobalActions();

  return (
    <MUISnackbar open={open} autoHideDuration={6000}>
      <Alert
        onClose={handleCloseSnackbar}
        severity={type}
        variant="filled"
        sx={{ width: '100%' }}
      >
        {message}
      </Alert>
    </MUISnackbar>
  );
};
