import React from 'react';

import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Container, Description, Title } from './styles';

const RejectedPage = () => {
  const navigate = useHistory();

  const handleNavigate = () => () => {
    navigate.push('/');
  };

  return (
    <Container style={{ maxWidth: 700 }}>
      <img src="/images/failed.svg" alt="rejected" />
      <Title variant="h4">Lo sentimos</Title>
      <Description>
        Tu préstamo no fue autorizada, pero no te preocupes podrás intentar en 3
        meses
      </Description>
      <Button className="button" onClick={handleNavigate()} fullWidth>
        Volver a la página principal
      </Button>
    </Container>
  );
};

export default RejectedPage;
