import React from 'react';
import { LinksContainer } from './styles';
import { Container, Description, Title } from '../Rejected/styles';

const Success = () => {
  return (
    <Container style={{ maxWidth: 700 }}>
      <img
        className="hero"
        src="/images/logo-new-verde.png"
        alt="Logo prestavale"
      />
      <Title variant="h4">Ya casi terminamos.</Title>
      <Description>Descarga la app y realiza los pasos restantes</Description>
      <LinksContainer>
        <a
          href="https://apps.apple.com/mx/app/distribuidoras-m%C3%B3vil-corp-150/id1499742551"
          target="_blank"
        >
          <img src="/images/app-store.png" alt="Download app store" />
        </a>
        <a
          className="play-store"
          href="https://play.google.com/store/apps/details?id=mx.roket.mada.distribuidoras"
          target="_blank"
        >
          <img src="/images/play-store.png" alt="Download play store" />
        </a>
      </LinksContainer>
    </Container>
  );
};

export default Success;
