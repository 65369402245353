import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 78px);
  text-align: center;
  gap: 15px;
  max-width: 500px;
  margin: auto;
  padding: 15px;

  img {
    width: 100%;
    max-width: 150px;
    margin-top: 24px;
  }

  .button {
    background-color: #00ae42;
    padding: 15px;
    border-radius: 24px;
    color: #fff;
    font-weight: bold;
  }

  .button:hover {
    background: #0dd260;
  }
`;

export const Title = styled(Typography)`
  font-size: 28px;
`;

export const Description = styled.p`
  margin: 0;
  font-size: 22px;
  width: 100%;
  max-width: 650px;
`;
