import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Navbar from '../Navbar';
import Footer from '../Footer';
import { mediaQuerySmall } from '../../helper';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;

  & main {
    margin-top: 10px;
    flex-grow: 1;
  }

  & .children {
    margin: 40px auto 0;
  }

  .whatsapp {
    position: fixed;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    bottom: 16px;
    right: 16px;
    background: #37d043;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: opacity 0.25s;
    z-index: 999;
  }

  .whatsapp:hover {
    opacity: 0.7;
  }

  .whatsapp > img {
    width: 32px;
    height: 32px;
  }

  ${mediaQuerySmall} {
    & .children {
      margin-top: 40px;
    }

    & main {
      padding: 80px 16px;
    }
  }
`;

export default function MainLayout({ children }) {
  return (
    <Content>
      <Navbar />
      <main>
        <div className="children">{children}</div>
      </main>
      <Footer />
      <a
        href="https://api.whatsapp.com/send?phone=5216675067979"
        rel="noopener noreferrer"
        target="_blank"
        className="whatsapp"
      >
        <img src="./images/whatsapp.svg" alt="icon whatsapp" />
      </a>
    </Content>
  );
}

MainLayout.propTypes = {
  children: PropTypes.any.isRequired,
};
