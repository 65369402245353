import PropTypes from 'prop-types';
import React from 'react';
import { useMediaQuery } from '@material-ui/core';
// import { BotonQuieroSerDistribuidora } from '../CommonElements';
import { media } from '../../helper';
import {
  BackGroundContainer,
  MainContainer,
  Section,
  TextSection,
} from './styledComponents';

const variantMapping = {
  primary: {
    background: '#1b5e20',
    header: '#81C784',
    title: '#ffffff',
    body: '#ffffff',
  },
  secondary: {
    background: '#e8f5e9',
    header: '#398245',
    title: '#272a2d',
    body: '#272a2d',
  },
};

const ColoredSection = ({
  variant,
  color,
  reversed,
  header,
  title,
  body,
  section,
  hasButton = true,
}) => {
  const isMobile = useMediaQuery(media.medium);

  return (
    <BackGroundContainer
      style={{
        backgroundColor: color || variantMapping[variant].background,
      }}
    >
      <MainContainer
        style={{
          // eslint-disable-next-line no-nested-ternary
          flexDirection: isMobile ? 'column' : reversed ? 'row' : 'row-reverse',
        }}
      >
        <TextSection>
          <div
            style={{
              color: variantMapping[variant].header,
              fontSize: '24px',
              lineHeight: '32px',
            }}
          >
            {header}
          </div>
          <div
            style={{
              color: variantMapping[variant].title,
              fontSize: '40px',
              lineHeight: '48px',
            }}
          >
            {title}
          </div>
          <div
            style={{
              color: variantMapping[variant].body,
              fontSize: '20px',
              lineHeight: '24px',
            }}
          >
            {body}
          </div>
          {/* {hasButton && <BotonQuieroSerDistribuidora />} */}
        </TextSection>
        <Section>{section}</Section>
      </MainContainer>
    </BackGroundContainer>
  );
};

ColoredSection.propTypes = {
  variant: PropTypes.string,
  color: PropTypes.string,
  reversed: PropTypes.bool,
  header: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.string,
  section: PropTypes.element,
  hasButton: PropTypes.bool,
};

export default ColoredSection;
