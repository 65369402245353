import { Button } from '@material-ui/core';
import styled from 'styled-components';

export const OTPContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  margin-top: 50px;

  .otp-inputs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 300px;
    margin: 0 auto 24px;
  }

  .otp-inputs input {
    width: 50px;
    height: 50px;
    border: 1px solid #c4c4c4;
    border-radius: 8px;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    color: #333333;
    margin: 0 5px;
    padding: 0;
  }

  .otp-inputs input:focus {
    outline: #00bc70 auto 1px;
  }
`;

export const PhoneContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;
`;
