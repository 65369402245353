import styled from 'styled-components';

export const Container = styled.div`
  h3 {
    color: #773dbd;
    margin-bottom: 24px;
    font-size: 28px;
    text-align: center;
  }

  h3 span {
    color: #00bc70;
  }

  @media screen and (min-width: 600px) {
    h3 {
      font-size: 32px;
      text-align: left;
    }
  }
`;

export const BottomMessage = styled.div`
  background-color: #f5f5f5;
  border-radius: 16px;
  padding: 16px;
  margin: 32px 0;
`;

export const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  a:first-child {
    margin-bottom: 24px;
  }

  a {
    width: 200px;
    height: 70px;
    border-radius: 16px;
    overflow: hidden;
  }

  a img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .play-store {
    padding: 16px;
    background-color: black;
  }

  @media screen and (min-width: 768px) {
    flex-direction: row;
    a:first-child {
      margin-bottom: 0;
      margin-right: 16px;
    }
  }
`;
