import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;

  p {
    max-width: 280px;
    margin: 0 auto 24px;
  }
`;
